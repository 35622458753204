
export function forgetPassword(query) {
    const mockData = {
        status: 200,
        data: {'msg': 'Reset link sent successfully, Please check your email.'}
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function resetPassword(query) {
    const mockData = {
        status: 200,
        data: {'msg': 'Reset link sent successfully, Please check your email.'}
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
